import {
  selectCheckout,
  updateForm,
  useAppDispatch,
  useAppSelector,
  validateOrder,
} from '@open-tender/cloud'
import { CheckoutErrorMessages } from '@open-tender/types'
import { formatDollars } from '@open-tender/utils'
import { Checkbox, FormError } from 'components/inputs'
import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Body from 'components/Body'

const DownsHeartLogo = require('../../../assets/images/downs_heart.png')

const makeErrorMsgs = (errors: CheckoutErrorMessages) => {
  if (!errors.surcharges) return null
  return Object.values(errors.surcharges).filter(
    (i) => typeof i === 'string'
  ) as string[]
}

const DonationWidget = styled.div`
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.tertiary};
  background: ${(props) => props.theme.colors.light};
  display: flex;
  padding: 16px 10px;
  margin: 1rem 0;
  align-items: center;
`

const DonationWidgetImage = styled.img`
  width: 72px;
  height: 57px;
`
const DonationWidgetContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  label {
    margin: 0 0 0.7rem;
    span {
      color: ${(props) => props.theme.colors.primary};
      font-weight: 700;
      align-items: center;
    }

    & > span > span {
      border-color: ${(props) => props.theme.colors.tertiary};
      margin: 0 0.5rem 0 0;
    }

    & > span > span::before {
      color: ${(props) => props.theme.colors.tertiary};
    }
  }
`
const DonationCheckbox = styled(Checkbox)``
const DonationWidgetDescription = styled(Body)``

const CheckoutDonationWidget = () => {
  const dispatch = useAppDispatch()
  const { check, form, loading, errors } = useAppSelector(selectCheckout)
  const [pendingSurcharge, setPendingSurcharge] = useState<number | null>(null)
  const surchargeIds = form.surcharges.map((i) => i.id)
  const errMsgs = makeErrorMsgs(errors)
  const total = check?.totals.total
  const isCheckoutLoading = loading === 'pending'

  useEffect(() => {
    if (!isCheckoutLoading) setPendingSurcharge(null)
  }, [isCheckoutLoading])

  const donationSurcharges = check?.config.surcharges.filter(
    (s) => s.is_donation
  )
  const hasDonationSurcharges =
    donationSurcharges?.length && donationSurcharges.length > 0
  const applySurcharge = async (surchargeId: number) => {
    setPendingSurcharge(surchargeId)
    const newSurcharge = { id: surchargeId }
    await dispatch(
      updateForm({ surcharges: [...form.surcharges, newSurcharge] })
    )
    dispatch(validateOrder())
  }

  const removeSurcharge = async (surchargeId: number) => {
    const filtered = form.surcharges.filter((i) => i.id !== surchargeId)
    await dispatch(updateForm({ surcharges: filtered }))
    dispatch(validateOrder())
  }

  if (!hasDonationSurcharges && !errMsgs) return null

  return (
    <div>
      {errMsgs?.map((errMsg) => (
        <FormError errMsg={errMsg} />
      ))}
      {donationSurcharges?.map((i) => {
        const isApplied = surchargeIds.includes(i.id)
        const isPending = i.id === pendingSurcharge
        const onPress = isApplied
          ? () => removeSurcharge(i.id)
          : () => applySurcharge(i.id)
        const cost =
          parseFloat(i.amount) > 0 ? `${formatDollars(i.amount)}` : 'no charge'

        const totalRoundUp = total
          ? formatDollars(`${Math.ceil(parseFloat(total))}`)
          : ''

        return (
          <DonationWidget key={i.id}>
            <DonationWidgetImage src={DownsHeartLogo} alt="downs_heart" />
            <DonationWidgetContent>
              <DonationCheckbox
                type="checkbox"
                label="Round Up for Down Syndrome ?"
                name={i.name}
                on={isApplied}
                onChange={onPress}
                disabled={isPending}
              />
              <DonationWidgetDescription size="small">
                Donate <b>{cost}</b> to the National Down Syndrome Adoption
                Network by Rounding up to <b>{totalRoundUp}</b>
              </DonationWidgetDescription>
            </DonationWidgetContent>
          </DonationWidget>
        )
      })}
    </div>
  )
}

export default CheckoutDonationWidget
