import { useCallback, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { deviceType, isMobile } from 'react-device-detect'
import {
  handleCheckoutError,
  isEmpty,
  makeCheckError,
  makeDeviceType,
} from '@open-tender/utils'
import {
  resetCheck,
  resetConfirmation,
  resetErrors,
  resetTip,
  selectBrand,
  selectCartTotal,
  selectCheckout,
  selectConfirmationOrder,
  selectContent,
  selectCustomer,
  selectMenuSlug,
  selectOrder,
  setConfirmationOrder,
  setDeviceType,
  setSubmitting,
  validateOrder,
  useAppDispatch,
  useAppSelector,
  fetchLocation,
  openModal,
  updateForm,
  updateCheckoutCustomer,
} from '@open-tender/cloud'
import { CustomerCreate } from '@open-tender/types'
import { FormError, Content, Main } from 'components'
import CheckoutCancelEdit from './CheckoutCancelEdit'
import CheckoutCustomer from './CheckoutCustomer'
import CheckoutGuest from './CheckoutGuest'
import CheckoutPickup from './CheckoutPickup'
import CheckoutDelivery from './CheckoutDelivery'
import CheckoutCart from './CheckoutCart'
import CheckoutHeader from './CheckoutHeader'
import CheckoutAddress from './CheckoutAddress'
import CheckoutDetails from './CheckoutDetails'
import CheckoutCurbside from './CheckoutCurbside'
import CheckoutSurcharges from './CheckoutSurcharges'
import CheckoutDiscounts from './CheckoutDiscounts'
import CheckoutPromoCodes from './CheckoutPromoCodes'
import CheckoutTip from './CheckoutTip'
import CheckoutGiftCards from './CheckoutGiftCards'
import CheckoutTenders from './CheckoutTenders'
import CheckoutSection from './CheckoutSection'
import CheckoutSubmit from './CheckoutSubmit'
import {
  makeAddressError,
  makeCustomerError,
  makeFormTitle,
} from './Checkout.utils'
import {
  CheckoutContent,
  CheckoutInfo,
  CheckoutSidebar,
  CheckoutSidebarContent,
  CheckoutTitle,
  CheckoutView,
} from './Checkout.styled'
import CheckoutValutec from './CheckoutValutec'
import { sendCheckoutView } from 'app/analytics'

const Checkout = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { title, has_promo_codes, has_gift_cards, has_valutec } =
    useAppSelector(selectBrand) || {}
  const { checkout: config } = useAppSelector(selectContent) || {}
  const cartTotal = useAppSelector(selectCartTotal)
  const menuSlug = useAppSelector(selectMenuSlug)
  const { serviceType, revenueCenter, requestedAt } =
    useAppSelector(selectOrder)
  const { revenue_center_id: revenueCenterId } = revenueCenter || {}
  const { auth, profile } = useAppSelector(selectCustomer)
  const hasCustomer = auth ? true : false
  const { check, form, errors, submitting, completedOrder } =
    useAppSelector(selectCheckout)
  const hasGiftCards =
    has_gift_cards && check?.config.tender_types.includes('GIFT_CARD')
  const hasValutec =
    has_valutec && check?.config.tender_types.includes('GIFT_CARD')
  const confirmedOrder = useAppSelector(selectConfirmationOrder)
  const hasConfirmationOrder = confirmedOrder ? true : false
  const hasCheck = check ? true : false
  const hasFormCustomer = !isEmpty(form.customer) ? true : false
  const formError = errors ? (errors.form as string) || null : null
  const customerError = makeCustomerError(errors)
  const checkoutError = customerError || formError
  const addressError = makeAddressError(errors)
  const deviceTypeName = makeDeviceType(deviceType)
  const { formTitle, hasAddress } = makeFormTitle(check, serviceType)
  const checkError = check?.errors ? makeCheckError(check.errors) : null
  const errMessages = checkError ? handleCheckoutError(checkError) : {}
  const cartError = (errMessages.cart as string) || null
  const isMissingNames = auth && (!profile?.first_name || !profile.last_name)

  useEffect(() => {
    if (!submitting && formError) window.scrollTo(0, 0)
  }, [formError, submitting])

  useEffect(() => {
    if (revenueCenterId) {
      const total = cartTotal.toFixed(2)
      dispatch(fetchLocation({ revenueCenterId, cartTotal: total }))
    }
  }, [revenueCenterId, cartTotal, dispatch])

  useEffect(() => {
    dispatch(setSubmitting(false))
    dispatch(resetConfirmation())
    dispatch(setDeviceType(deviceTypeName))
    return () => {
      dispatch(resetErrors())
      dispatch(resetTip())
      dispatch(resetCheck())
    }
  }, [dispatch, deviceTypeName])
  const checkLoaded = check && Boolean(check.cart)
  useEffect(() => {
    if (checkLoaded) sendCheckoutView(check)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkLoaded])

  const updateCustomer = useCallback(
    async (data: CustomerCreate) => {
      const { first_name, last_name, email, phone, company } = data
      const customer = {
        customer_id: profile?.customer_id,
        first_name,
        last_name,
        email,
        phone,
        company,
      }
      await dispatch(updateForm({ customer }))
      dispatch(updateCheckoutCustomer({ customer }))
      dispatch(validateOrder())
    },
    [dispatch, profile?.customer_id]
  )

  const openUpdateCustomerModal = useCallback(() => {
    dispatch(openModal({ type: 'profile', args: { callback: updateCustomer } }))
  }, [dispatch, updateCustomer])

  useEffect(() => {
    if (isMissingNames) {
      openUpdateCustomerModal()
      return
    }
    if (!completedOrder && hasFormCustomer && !hasCheck) {
      dispatch(validateOrder())
    }
  }, [
    dispatch,
    completedOrder,
    hasFormCustomer,
    hasCheck,
    isMissingNames,
    openUpdateCustomerModal,
  ])

  useEffect(() => {
    if (isMissingNames) {
      openUpdateCustomerModal()
      return
    }
    if (hasCheck) {
      dispatch(validateOrder())
    }
  }, [dispatch, hasCheck, isMissingNames, openUpdateCustomerModal, requestedAt])

  useEffect(() => {
    if (completedOrder) {
      if (!hasConfirmationOrder) {
        dispatch(setConfirmationOrder(completedOrder))
        navigate('/confirmation')
      }
    } else if (cartTotal === 0) {
      navigate(menuSlug)
    } else if (!revenueCenterId || !serviceType) {
      navigate('/account')
    } else if (!hasCustomer && !hasFormCustomer) {
      navigate('/checkout/guest')
    }
  }, [
    dispatch,
    navigate,
    cartTotal,
    menuSlug,
    revenueCenterId,
    serviceType,
    completedOrder,
    hasCustomer,
    hasFormCustomer,
    hasConfirmationOrder,
  ])

  return (
    <>
      <Helmet>
        <title>Checkout | {title}</title>
      </Helmet>
      <Content>
        <CheckoutHeader />
        <Main style={{ overflow: 'hidden' }}>
          <CheckoutView>
            <CheckoutContent>
              <CheckoutTitle>
                <h1>{config?.title}</h1>
                <p>{config?.subtitle}</p>
                <CheckoutCancelEdit />
                {checkoutError && <FormError errMsg={checkoutError} />}
                {addressError && <FormError errMsg={addressError} />}
                {cartError && <FormError errMsg={cartError} />}
              </CheckoutTitle>
              <CheckoutInfo>
                {auth ? <CheckoutCustomer /> : <CheckoutGuest />}
                {check && (
                  <>
                    {['WALKIN', 'PICKUP'].includes(serviceType ?? 'WALKIN') ? (
                      <CheckoutPickup />
                    ) : serviceType === 'DELIVERY' ? (
                      <CheckoutDelivery />
                    ) : null}
                  </>
                )}
              </CheckoutInfo>
              {formTitle && (
                <CheckoutSection title={formTitle} style={{ padding: '0' }}>
                  <CheckoutAddress />
                  <CheckoutDetails hasAddress={hasAddress} />
                </CheckoutSection>
              )}
              {check && (
                <>
                  <CheckoutCurbside setInitialValue={!formTitle} />
                  <CheckoutSurcharges />
                  <CheckoutDiscounts />
                  {has_promo_codes && <CheckoutPromoCodes />}
                  <CheckoutTip />
                  {isMobile && <CheckoutCart />}
                  {hasGiftCards && <CheckoutGiftCards />}
                  {hasValutec && <CheckoutValutec />}
                  <CheckoutTenders />
                  <CheckoutSubmit />
                </>
              )}
            </CheckoutContent>
            <CheckoutSidebar>
              <CheckoutSidebarContent>
                {!isMobile && check && <CheckoutCart />}
              </CheckoutSidebarContent>
            </CheckoutSidebar>
          </CheckoutView>
        </Main>
      </Content>
    </>
  )
}

export default Checkout
