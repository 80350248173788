import styled from '@emotion/styled'
import {
  CheckoutCheck,
  CheckoutTender,
  Money,
  TenderType,
} from '@open-tender/types'
import { checkAmountRemaining } from '@open-tender/utils'
import {
  selectCheckout,
  updateForm,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import CheckoutApplePay from './CheckoutApplePay'
import CheckoutGooglePay from './CheckoutGooglePay'

const checkHasApplePay = (check: CheckoutCheck | null) => {
  return check ? check.config.tender_types.includes('APPLE_PAY') : false
}

const checkHasGooglePay = (check: CheckoutCheck | null) => {
  return check ? check.config.tender_types.includes('GOOGLE_PAY') : false
}

const CheckoutPayView = styled.div`
  // max-width: 36rem;
  width: 100%;
  margin: 0 auto 3rem;
  text-align: center;
`

const CheckoutPay = () => {
  const dispatch = useAppDispatch()
  const { check, form, errors } = useAppSelector(selectCheckout)
  const tenderErrors = errors.tenders || null
  const tenderIndex = form.tenders.findIndex(
    (i) => i.tender_type !== 'GIFT_CARD'
  )
  const tenderError =
    tenderErrors && typeof tenderErrors !== 'string'
      ? (tenderErrors[tenderIndex] as Record<string, string>)
      : null
  const total = check?.totals.total
  const deposit = check?.deposit
  const amountDue = deposit || total
  const remaining = amountDue
    ? checkAmountRemaining(amountDue, form.tenders)
    : 0.0
  const amount = remaining.toFixed(2) as Money

  // Apple Pay
  const hasApplePay = checkHasApplePay(check)
  const applePayError = tenderError ? tenderError.apple_pay || null : null

  // Google Pay
  const hasGooglePay = checkHasGooglePay(check)
  const googlePayError = tenderError ? tenderError.google_pay || null : null

  if (process.env.NODE_ENV !== 'development') {
    // eslint-disable-next-line no-console
    console.log('hasApplePay', hasApplePay, 'hasGooglePay', hasGooglePay)
  }

  const addTender = (tender: CheckoutTender) => {
    const newTender = { ...tender, amount }
    const currentTenders = form.tenders.filter(
      (i) => i.tender_type !== newTender.tender_type
    )
    dispatch(updateForm({ tenders: [...currentTenders, newTender] }))
  }

  const removeTender = (tenderType: TenderType) => {
    const filtered = form.tenders.filter((i) => i.tender_type !== tenderType)
    const nonGiftCard = filtered.filter((i) => i.tender_type !== 'GIFT_CARD')
    const giftCard = filtered.filter((i) => i.tender_type === 'GIFT_CARD')
    let remaining = amountDue
      ? checkAmountRemaining(amountDue, nonGiftCard)
      : 0.0
    const adjusted = nonGiftCard.map((i) => {
      const newAmount = remaining
      remaining -= newAmount
      return { ...i, amount: newAmount.toFixed(2) as Money }
    })
    const nonZero = adjusted.filter((i) => i.amount !== '0.00')
    dispatch(updateForm({ tenders: [...giftCard, ...nonZero] }))
  }

  if (!hasApplePay && !hasGooglePay) return null

  return (
    <CheckoutPayView>
      {hasApplePay && (
        <CheckoutApplePay
          amount={amount}
          error={applePayError}
          addTender={addTender}
          removeTender={removeTender}
        />
      )}
      {hasGooglePay && (
        <CheckoutGooglePay
          amount={amount}
          error={googlePayError}
          addTender={addTender}
          removeTender={removeTender}
        />
      )}
    </CheckoutPayView>
  )
}

export default CheckoutPay
